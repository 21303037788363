import { FormikErrors, useFormik } from "formik"

import { TOrganizationNameForm } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/OrganizationNameStep/OrganizationNameStep"
import { useTranslate } from "src/i18n/useTranslate"

export function useOrganizationNameForm({
  initialValues,
  onSubmit,
}: {
  initialValues: TOrganizationNameForm
  onSubmit: (values: TOrganizationNameForm) => void
}) {
  const { t, langKeys } = useTranslate()

  const nameForm = useFormik<TOrganizationNameForm>({
    initialValues,
    validate: (values) => {
      const errors: FormikErrors<TOrganizationNameForm> = {}
      if (!values.name) {
        errors.name = t(langKeys.onboarding_organization_name_error_text)
      }
      return errors
    },
    onSubmit,
  })

  return nameForm
}
